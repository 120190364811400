import React, { useEffect } from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";
import PGSideBar from '../components/pg-side-bar';

import '../styles/cus.css';

const PatentGuide = () => {
    const seo = {
        metaDesc: 'Patent Guide - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="Patent Guide - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area">
                        <h1 class="d-clr" style={{ color: '#fbb848' }}>Patent Guide</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <h2>Understand Provisional Patent.</h2>
                            <p>&nbsp;</p>
                            <h4><a href="/patent-guide/what-are-the-benefits-vs-drawbacks-of-filing-a-provisional-patent/" class="d-clr">What Are the Benefits vs Drawbacks of Filing A Provisional Patent?</a></h4>
                            <p class="f-16">Provides basic protection for the idea disclosed in the PPA for one year while you evaluate the business and/or technical merits of your idea…</p>
                            <h4><a href="/patent-guide/how-to-do-patent-search-yourself-diy-the-right-way/" class="d-clr">How to Do Patent Search Yourself (DIY) The Right Way?</a></h4>
                            <p class="f-16">It’s best to visit a USPTO research facility.&nbsp; The one in Alexandria, Virginia is preferred because its computers have the Exam Assisted Searching Tool (EAST) and you can talk directly with examiners and other staffers….</p>
                            <h4><a href="/patent-guide/how-to-start-your-utility-patent-application-process-in-4-stephow-to-use-a-professional-patent-searcher/" class="d-clr">How to Use a Professional Patent Searcher?</a></h4>
                            <p class="f-16">If you don’t want to research yourself, you can hire professional patent researchers, such as a patent attorney or agent.&nbsp; One advantage of hiring a professional is that he/she understands the concept…</p>
                            <h4><a href="/patent-guide/how-to-start-your-utility-patent-application-process-in-4-steps/" class="d-clr">How to Start Your Utility Patent Application Process in 4 Steps</a></h4>
                            <p class="f-16">The process for applying for a patent is known as patent prosecution.&nbsp; Here are some things you should do before starting the application process….</p>
                            <h4><a href="/patent-guide/understand-american-invents-act-aia/" class="d-clr">Understand American Invents Act (AIA)</a></h4>
                            <p class="f-16">The American Invents Act has dramatically changed how the patent system operates.&nbsp; Here’s a brief overview of some of the changes it entails and what it means for you….</p>
                            <h4><a href="/patent-guide/designpatent/" class="d-clr">How Is Design Patent Different From Utility Patent?</a></h4>
                            <p class="f-16">Design patents protect how something looks, while utility patents protect how something operates and the way it is constructed.&nbsp; Consequently, to obtain a design patent, the applicant must…</p>
                            <h4><a href="/patent-guide/one-year-rule/" class="d-clr">What is the One Year Rule?</a></h4>
                            <p class="f-16">In the United States,<em>&nbsp;<strong>a patent application must be filed within one year after an inventor sells his/her idea, offers it for sale, or publicly or commercially describes or uses it</strong></em>.&nbsp; If the USPTO issues…</p>
                            <h4><a href="/patent-guide/when-is-invention-date-established/" class="d-clr">When is Invention Date established?</a></h4>
                            <p class="f-16">This can be the date on which the:</p>
                            <ul>
                                <li>patent application was filed…</li>
                            </ul>
                            <h4><a href="/patent-guide/how-to-know-if-your-invention-is-novel/" class="d-clr">How to know if your invention is “Novel”?</a></h4>
                            <p class="f-16">Patented inventions&nbsp;<i>must</i> be different from existing knowledge or previous inventions, otherwise known as prior art. What is prior art?…</p>
                            <h4><a href="/patent-guide/ask-yourself-these-7-questions-to-determine-whether-you-should-get-a-patent/" class="d-clr">Ask yourself these 7 questions to determine whether you should get a patent!</a></h4>
                            <p class="f-16"> <strong><em>1.&nbsp; Is it commercializable?</em></strong></p>
                            <p class="f-16">Look at the cost of your invention, competitors’ products, the invention’s ease of use, and consumer demand…</p>
                            <h4><a href="/patent-guide/what-are-the-five-patent-classes/" class="d-clr">What Are The Five Patent Classes?</a></h4>
                            <p class="f-16">These are steps for doing or making something.&nbsp; They can be steps that change something physically or even manual steps, like how you type on a keyboard.&nbsp; They cannot, however, consists of only mental steps…</p>
                            <h4><a href="/patent-guide/9-important-elements-of-a-provisional-patents/" class="d-clr">9 Important Elements of A Provisional Patents</a></h4>
                            <p class="f-16">The title is a short description of your invention. It should be less than 500 characters….</p>
                            <h4><a href="/patent-guide/iix-what-happens-after-filing-a-utility-patent-application/" class="d-clr">What happens after filing a utility patent application?</a></h4>
                            <p class="f-16">The initial steps that the USPTO takes are similar to what it does for the PPA.&nbsp; Here’s a brief overview of the process…</p>
                            <h4><a href="/patent-guide/what-are-optional-elements-of-a-provisional-patent/" class="d-clr">What Are Optional Elements Of A Provisional Patent?</a></h4>
                            <p class="f-16">This is not required in a PPA but it is required in a utility patent application.&nbsp; You should think about these because patent protection rest on what claims you are asserting in your application. …</p>
                            <h4><a href="/patent-guide/what-to-do-after-the-uspto-has-issued-your-patent/" class="d-clr">What To Do After The USPTO Has Issued Your Patent?</a></h4>
                            <ol>
                                <li><b><i>Enforcement</i></b></li>
                            </ol>
                            <p class="f-16">As earlier stated, while the application provides protection for the invention, until the application is published, you should still require confidentiality agreement to protect your invention…..</p>
                            <p>&nbsp;</p>
                            <div class="">
                                <h3><strong>About Inventiv.org&nbsp;ProvisionalBuilder™?</strong></h3>
                                <h4><a href="/patent-guide/6-benefits-to-using-provisionalbuilder/" class="d-clr">6 benefits to using ProvisionalBuilder™?</a></h4>
                                <h4><a href="/patent-guide/how-does-provisionalbuilder-work/" class="d-clr">How does ProvisionalBuilder™ work?</a></h4>
                                <h4><a href="/patent-guide/how-do-i-install-provisionalbuilder/" class="d-clr">How do I install ProvisionalBuilder™?</a></h4>
                                <h4><a href="/patent-guide/what-do-i-do-after-provisionalbuilder-is-installed/" class="d-clr">What do I do after ProvisionalBuilder™ is installed?</a></h4>
                                <h4><a href="/patent-guide/how-do-i-start-an-application-on-provisionalbuilder/" class="d-clr">How do I start an application on ProvisionalBuilder?</a></h4>
                            </div>
                            <a href="/inventor-resources" class="btn btn-default">Sign up for Cloud Based Provisional Builder Software</a>
                        </div>
                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide;